exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-blog-autumn-term-2023-index-mdx": () => import("./../../../src/blog/autumn-term-2023/index.mdx" /* webpackChunkName: "component---src-blog-autumn-term-2023-index-mdx" */),
  "component---src-blog-christmas-2022-index-mdx": () => import("./../../../src/blog/christmas-2022/index.mdx" /* webpackChunkName: "component---src-blog-christmas-2022-index-mdx" */),
  "component---src-blog-hello-world-mdx": () => import("./../../../src/blog/hello-world.mdx" /* webpackChunkName: "component---src-blog-hello-world-mdx" */),
  "component---src-blog-return-index-mdx": () => import("./../../../src/blog/return/index.mdx" /* webpackChunkName: "component---src-blog-return-index-mdx" */),
  "component---src-blog-were-back-mdx": () => import("./../../../src/blog/were-back.mdx" /* webpackChunkName: "component---src-blog-were-back-mdx" */),
  "component---src-blog-winter-term-2024-index-mdx": () => import("./../../../src/blog/winter-term-2024/index.mdx" /* webpackChunkName: "component---src-blog-winter-term-2024-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

